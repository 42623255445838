import React from "react";
import './LoginForm.css'
import {ReactComponent as LogoDark} from "../../images/logo_dark.svg";
import PhoneInput from "./PhoneInput/PhoneInput";
import CheckCode from "./CheckCode/CheckCode";

class LoginForm extends React.Component {
    state = {
        isSent: false
    }

    render() {
        return (
            <div className="login-form">
                <a href="/" className="login-form__logo"><LogoDark/></a>
                <h1 className="login-form__title">Вхід в адмін панель</h1>
                {this.state.isSent ?
                    <CheckCode phone={this.props.phone} setToken={this.props.setToken} changeNumber={val => this.setState(val)}/> :
                    <PhoneInput phone={this.props.phone} setPhone={this.props.setPhone} success={() => this.setState({isSent: true})}/>}
            </div>
        );
    }
}

export default LoginForm