import React from "react";
import './AddUserModal.css';
import {ReactComponent as CloseIcon} from "../../../images/close_icon.svg";
import ModalOverlay from "../../UI/ModalOverlay/ModalOverlay";
import {ReactComponent as AddIcon} from "../../../images/add.svg";
import axios from "axios";
import Api from "../../../Api/Api";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import RemoveToken from "../../../Api/RemoveToken";
toastr.options = {
    "closeButton": false,
    "debug": false,
    "newestOnTop": false,
    "progressBar": true,
    "positionClass": "toast-top-right",
    "preventDuplicates": false,
    "onclick": null,
    "showDuration": "300",
    "hideDuration": "1000",
    "timeOut": "5000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
};

class UpdateUserModal extends React.Component {
    constructor(props) {
        super(props)
        this.formRef = React.createRef();
        this.editUser = this.editUser.bind(this);
    }

    state = {
        image: ''
    }

    editUser (e) {
        let
            data = {},
            errors = 0;

        e.preventDefault();

        const error = (input) => {
            input.classList.add('_error');
            errors++;
        }
        const success = (input) => {
            data[input.name] = input.value;
        }

        for (let el of this.formRef.current.elements) {
            if (el.name && el.name !== 'image') {
                if (el.type === 'radio') {
                    if (el.checked) {
                        data[el.name] = el.value;
                    }
                } else {
                    if (el.name === 'phone' || el.name === 'email') {
                        const regex = el.name === 'phone' ?
                            /^\+?3?8?(0\d{9})$/ :
                            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

                        if (regex.test(el.value)) {
                            success(el);
                        } else {
                            error(el);
                        }
                    } else {
                        if (el.value.trim() === '') {
                            error(el);
                        } else {
                            success(el);
                        }
                    }
                }
            }
        }

        if (!errors) {
            axios({
                method: 'post',
                url: Api.link + 'user/edit',
                data: {
                    phone: localStorage.getItem('phone'),
                    token: localStorage.getItem('token'),
                    name: data.name,
                    email: data.email,
                    job_title: data.position,
                    level: data.rights,
                    id: this.props.userData.phone.replace(/\D+/g, '')
                }
            }).then(res => {
                if (res.data.success) {
                    this.props.addCb();
                    this.props.closeCb();
                    setTimeout(() => this.props.clearData(), 300);

                    if (this.props.userData.phone === localStorage.getItem('phone')) this.props.changeThisUser();
                    toastr.clear();
                    toastr.success('Користувач успішно оновлений');
                }
            }).catch(RemoveToken);
        }
    }
    closeHandler = (e) => {
        e.preventDefault();
        this.props.closeCb();
        setTimeout(() => {
            this.props.clearData();
            for (let key of this.formRef.current.elements) {
                key.classList.remove('_error');
            }
        }, 300);
    }
    imgUploadHandler = (e) => {
        const reader = new FileReader();
        const file = e.target.files[0];

        reader.onload = (upload) => {
            this.setState({
                image: upload.target.result
            });
        };
        reader.readAsDataURL(file);
    }

    render() {
        return (
            <div className={`add-user${this.props.opened ? ' opened' : ''}`}>
                <div className="add-user__content">
                    <h3 className="add-user__title">Редагування користувача</h3>
                    <button className="add-user__close" onClick={this.closeHandler}><CloseIcon/></button>
                    <form ref={this.formRef} action="" onSubmit={this.editUser} className="add-user__form form">
                        <div className="form-control double top ">
                            <div className={this.state.image ? 'uploaded' : ''}>
                                <label className="label" htmlFor="phoneUser">Фото користувача</label>
                                <input type="file"
                                       name="image"
                                       accept=".png,.jpg,.svg,.jpeg"
                                       className="input-file"
                                       id="phoneUser"
                                       onChange={this.imgUploadHandler}
                                />
                                <label htmlFor="phoneUser" className="label-file"><AddIcon/></label>
                                <span className="uploaded-img">
                                    <img src={this.state.image ? this.state.image : ''} alt="user_img"/>
                                    <button className="uploaded-img__remove" onClick={() => this.setState({image: ''})}><CloseIcon/></button>
                                </span>
                            </div>
                            <div>
                                <span className="label" style={({marginBottom: '15px'})}>Права доступу</span>
                                <label className="form-switch">
                                    <input
                                        type="radio"
                                        value="1"
                                        name="rights"
                                        checked={this.props.userData.level === '1'}
                                        onChange={e => this.props.updateData('level', e.target['value'])}
                                    />
                                    <span className="form-switch-slider"></span>
                                    <span className="form-switch-label">Менеджер</span>
                                </label>
                                <label className="form-switch">
                                    <input
                                        type="radio"
                                        value="2"
                                        name="rights"
                                        checked={this.props.userData.level === '2'}
                                        onChange={e => this.props.updateData('level', e.target['value'])}
                                    />
                                    <span className="form-switch-slider"></span>
                                    <span className="form-switch-label">Адміністратор</span>
                                </label>
                            </div>
                        </div>
                        <div className="form-control">
                            <label className="label" htmlFor="nameUser">Ім'я Прізвище</label>
                            <input
                                value={this.props.userData.name}
                                onInput={e => {
                                    e.target.classList.remove('_error');
                                    this.props.updateData('name', e.target['value'])
                                }}
                                type="text" name="name" className="input" id="nameUser" placeholder="Ім'я Прізвище"/>
                        </div>
                        <div className="form-control">
                            <label className="label" htmlFor="emailUser">Email (електронна пошта)</label>
                            <input
                                value={this.props.userData.email}
                                onInput={e => {
                                    e.target.classList.remove('_error');
                                    this.props.updateData('email', e.target['value'])
                                }}
                                type="email" className="input" id="emailUser" name="email"
                                placeholder="email@gmail.com"/>
                        </div>
                        <div className="form-control">
                            <label className="label" htmlFor="positionUser">Посада</label>
                            <input
                                value={this.props.userData.job_title}
                                onInput={e => {
                                    e.target.classList.remove('_error');
                                    this.props.updateData('job_title', e.target['value'])
                                }}
                                type="text" className="input" id="positionUser" name="position"
                                placeholder="Посада"/>
                        </div>
                        <div className="form-buttons add-user__buttons">
                            <button className="btn btn--secondary" onClick={this.closeHandler}>Скасувати</button>
                            <button className="btn btn--primary" type="submit">Зберегти</button>
                        </div>
                    </form>
                </div>
                <ModalOverlay cb={this.closeHandler}/>
            </div>
        );
    }
}

export default UpdateUserModal;