import React from 'react';
import Wrapper from "../../components/Wrapper/Wrapper";
import LoginForm from "../../components/LoginForm/LoginForm";
import './Login.css'
import {Navigate} from "react-router-dom";
import {seo} from "../../helpers/seo";

class Login extends React.Component {
    componentDidMount() {
        seo({
            title: 'Venbest Map - Login'
        });
    }

    render() {
        if (this.props.token && this.props.phone) return <Navigate to='/' />;

        return(
            <Wrapper customClass="login-wrapper">
                <div className="login-bg"></div>
                <div className="login-content">
                    <LoginForm phone={this.props.phone} setPhone={this.props.setPhone} setToken={this.props.setToken} />
                </div>
            </Wrapper>
        );
    }
}

export default Login;