import React from "react";
import {Route, Routes, Navigate, useLocation} from "react-router-dom";
import Login from "../pages/Login/Login";
import Home from "../pages/Home/Home";
import Gmr from "../pages/Gmr/Gmr";
import Clients from "../pages/Clients/Clients";
import Statistics from "../pages/Statistics/Statistics";
import Users from "../pages/Users/Users";
import {useState} from "react";
import axios from "axios";
import Api from "../Api/Api";
import RemoveToken from "../Api/RemoveToken";

const AppRouter = () => {
    const [session, setSession] = useState({
        token: localStorage.getItem('token'),
        phone: localStorage.getItem('phone')
    });
    const [userInfo, setUserInfo] = useState({
        name: '',
        job_title: '',
    });
    const location = useLocation();
    const finalSetPhone = val => {
        localStorage.setItem('phone', val);
        setSession({
            ...session,
            phone: val
        });
    };
    const finalSetToken = val => {
        localStorage.setItem('token', val);
        setSession({
            ...session,
            token: val
        });
    };
    const logoutFn = () => {
        localStorage.setItem('phone', '');
        localStorage.setItem('token', '');
        setSession({
            token: '',
            phone: ''
        });
        setUserInfo({
            name: '',
            job_title: '',
        });
    };

    if ((!session.phone || !session.token) && location.pathname !== '/login') return <Navigate to='/login'/>;

    if (session.token && session.phone && location.pathname !== '/login') {
        if (!userInfo.name && !userInfo.job_title) axios({
            method: 'post',
            url: Api.link + 'login/profile',
            data: {
                token: session.token,
                phone: session.phone,
            }
        }).then(res => {
            if (res.data.success) {
                setUserInfo(res.data.data);
            }
        }).catch(RemoveToken);
    }

    if (+userInfo.level === 1 && location.pathname !== '/') return <Navigate to='/'/>;

    return (
        <Routes>
            <Route path="/" element={<Home logoutFn={logoutFn} userInfo={userInfo} currentPage="home"/>}/>
            <Route path="/login"
                   element={<Login phone={session.phone} setPhone={finalSetPhone} token={session.token}
                                   setToken={finalSetToken}/>}/>
            <Route path="/gmr" element={<Gmr logoutFn={logoutFn} userInfo={userInfo} currentPage="gmr"/>}/>
            <Route path="/clients" element={<Clients logoutFn={logoutFn} userInfo={userInfo} currentPage="clients"/>}/>
            <Route path="/statistics"
                   element={<Statistics logoutFn={logoutFn} userInfo={userInfo} currentPage="statistics"/>}/>
            <Route path="/users"
                   element={<Users logoutFn={logoutFn}
                                   changeThisUser={() => setUserInfo({})}
                                   userInfo={userInfo}
                                   currentPage="users"/>}/>
        </Routes>
    );
};

export default AppRouter;