import './User.css';

function User({name, position, image}) {
    return(
        <div className="user">
            <div className="user__info">
                <div className="user__name">{name}</div>
                <div className="user__position">{position}</div>
            </div>
            <img src={image} alt="User" className="user__img"/>
        </div>
    );
}

export default User;