import React from "react";
import './ListGrm.css';
import {ReactComponent as CheckboxIcon} from "../../../images/checkbox.svg";
import {ReactComponent as BriefcaseIcon} from "../../../images/briefcase.svg";
import {ReactComponent as CloseIcon} from "../../../images/close_icon.svg";
import {ReactComponent as EditIcon} from "../../../images/edit_icon.svg";
import AutoRedImg from '../../../images/auto_red.png';
import AutoGreenImg from '../../../images/auto_green.png';
import ItemGrm from "./ItemGrm/ItemGrm";
import ItemGrmInfo from "./ItemGrmInfo/ItemGrmInfo";
import axios from "axios";
import Api from "../../../Api/Api";
import RemoveToken from "../../../Api/RemoveToken";
import turfCircle from '@turf/circle';

class ListGrm extends React.Component {
    state = {
        grmType: 'venbest',
        grmData: {},
        openedItem: false,
        preloading: true,
        grmSelectTab: 5,
        currentGrmData: {},
        gmrList: {
            venbest: [],
            other: [],
            all: []
        },
        trafficJams: '830',
        edit: false,
        changePreload: false
    }

    componentDidMount() {
        this.updateGmrList(this.props.gmrList);
    }

    updateGmrList(gmrList) {
        const newGmrList = {
            venbest: [],
            other: [],
            all: []
        }
        gmrList.forEach(grm => {
            let item = {
                img: grm.company === 'Venbest' ? AutoRedImg : AutoGreenImg,
                name: grm.name,
                phoneNumber: grm.phone,
                companyName: grm.company,
                latitude: grm.latitude,
                longitude: grm.longitude,
                city: grm.city,
                id: grm.id
            };

            newGmrList.all.push(item);
            grm.company === 'Venbest' ? newGmrList.venbest.push(item) : newGmrList.other.push(item);
        });
        this.setState({gmrList: newGmrList});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.gmrList !== prevProps.gmrList) {
            this.updateGmrList(this.props.gmrList);
        }
        if (prevProps.changedGeoMark[0] !== this.props.changedGeoMark[0] ||
            prevProps.changedGeoMark[1] !== this.props.changedGeoMark[1] ||
            prevState.changePreload !== this.state.changePreload
        ) {
            const data = {
                ...this.state.currentGrmData,
                latitude: this.props.changedGeoMark[1],
                longitude: this.props.changedGeoMark[0]
            }

            if (this.state.preloading) {
                this.updateGrmItemInfo(data);
            } else this.setState({preloading: true, changePreload: !this.state.changePreload});
        }
        if (!this.props.ready) return;
        if (this.props.opened) {
            if (prevState.grmType !== this.state.grmType) return;
            if (prevState.openedItem !== this.state.openedItem) return;
            if (prevState.grmSelectTab !== this.state.grmSelectTab) return;
            if (Object.keys(this.state.currentGrmData).length) {
                if (this.state.gmrList.all.length && this.state.trafficJams !== prevProps.trafficJams) {
                    this.props.refreshGmrMark([this.state.currentGrmData.longitude, this.state.currentGrmData.latitude], this.createdMarks(this.state.gmrList.all));
                }
            } else {
                if (this.state.gmrList.all.length) this.props.turnOnCb(this.createdMarks(this.state.gmrList.all));
            }
        } else {
            this.infoCloseHandler();
        }
        if (!this.props.opened) this.props.turnOffCb();
        if (this.state.openedItem) {
            if (this.state.trafficJams === this.props.trafficJams && this.state.grmSelectTab === prevState.grmSelectTab) return;

            if (this.state.preloading) {
                this.updateGrmItemInfo(this.state.currentGrmData);
            } else this.setState({preloading: true});
        }
    }

    updateGrmItemInfo = (data) => {
        if (!this.props.ready) return;
        const grmData = structuredClone(data);
        axios({
            method: 'post',
            url: Api.link + 'map/clients/list',
            data: {
                phone: localStorage.getItem('phone'),
                token: localStorage.getItem('token'),
                time: this.state.grmSelectTab,
                speed: this.state.trafficJams,
                latitude: data.latitude,
                longitude: data.longitude,
            }
        }).then(res => {
            if (res.data.success) {
                grmData.totalCount = res.data.totalCount;
                grmData.totalAmount = res.data.totalAmount;
                grmData.list = [];

                res.data.data.forEach(item => {
                    let distance = +item.distance / 1000;
                    grmData.list.push({
                        name: item.name,
                        companyName: item.owner,
                        location: item.address,
                        distance: `${distance.toFixed(2)} км.`,
                        price: item.amount
                    })
                });

                this.setState({
                    trafficJams: this.props.trafficJams,
                    grmData,
                    preloading: false
                });
            }
        }).catch(RemoveToken);
    }
    itemGrmHandler = data => {
        this.props.onClickGmr([data.longitude, data.latitude], 11.5, this.state.gmrList.all);
        this.setState({
            currentGrmData: data,
            preloading: true,
            openedItem: true,
        });
        this.updateGrmItemInfo(data);
    };
    infoCloseHandler = () => {
        if (this.state.gmrList.all.length && this.props.opened) this.props.closeItemInfoFb(this.createdMarks(this.state.gmrList.all));
        if (this.state.openedItem) this.setState({openedItem: false, currentGrmData: {}});
    }

    createdMarks = (data) => {
        const radius = this.props.trafficJams * 5 / 1000;
        const marks = [];
        data.forEach(gmr => {
            const
                center = [gmr.longitude, gmr.latitude],
                options = {steps: 64, units: 'kilometers', properties: {foo: 'bar'}},
                circle = turfCircle(center, radius, options);

            circle.properties.company = gmr.companyName;
            circle.properties.id = gmr.id;
            circle.properties.name = gmr.name;
            circle.properties.phone = gmr.phoneNumber;
            circle.properties.city = gmr.city;
            circle.properties.coordinates = [gmr.longitude, gmr.latitude];

            marks.push(circle);
        });
        return marks
    }


    infoEditHandler = (e) => {
        e.preventDefault();
        if (!this.props.edit) this.props.setEdit(true);
    }

    render() {
        const grmTypeHandler = e => this.setState({grmType: e.target.value});

        return (
            <div className={`list-grm__wrapper${this.props.opened ? ' visible' : ''}`}>
                <div className={`list-grm${!this.state.openedItem ? ' visible' : ''}`}>
                    <div className="list-grm__radios">
                        <label className={`list-grm__radio${this.state.grmType === 'venbest' ? ' active' : ''}`}>
                            <input
                                type="radio"
                                name="grm-type"
                                value="venbest"
                                onChange={grmTypeHandler}
                            />
                            <CheckboxIcon/> Венбест
                        </label>
                        <label className={`list-grm__radio${this.state.grmType === 'other' ? ' active' : ''}`}>
                            <input
                                type="radio"
                                name="grm-type"
                                onChange={grmTypeHandler}
                                value="other"/>
                            <CheckboxIcon/> Інші
                        </label>
                    </div>
                    <ul className="list-grm__list">
                        {this.state.gmrList[this.state.grmType].map((item, index) => (
                            <ItemGrm cb={this.itemGrmHandler} key={index} data={item}/>))}
                    </ul>
                </div>
                <div className={`grm-info${this.state.openedItem ? ' visible' : ''}`}>
                    <div className={`load${this.state.preloading ? ' visible' : ''}`}>
                        <hr/>
                        <hr/>
                        <hr/>
                        <hr/>
                    </div>
                    <div className={`grm-info__heading${this.state.preloading ? ' hidden' : ''}`}>
                        <div className="grm-info__short">
                            <div className="grm-info__short-info">
                                <img src={this.state.grmData.img} alt={this.state.grmData.name + '_img'}
                                     className="grm-info__img"/>
                                <div>
                                    <div className="grm-info__name">{this.state.grmData.name}</div>
                                    <div className="grm-info__number">{this.state.grmData.phoneNumber}</div>
                                </div>
                            </div>
                            <div className="grm-info__buttons">
                                <button className={`grm-info__close ${this.props.edit ? ' hidden' : ''}`}
                                        onClick={this.infoCloseHandler}>
                                    <CloseIcon/>
                                </button>
                                <button className="grm-info__edit" onClick={this.infoEditHandler}>
                                    <EditIcon/>
                                </button>
                            </div>
                        </div>
                        <div className="grm-info__company">
                            <BriefcaseIcon/> {this.state.grmData.companyName}
                        </div>
                    </div>
                    <div className={`grm-info__information${this.state.preloading ? ' hidden' : ''}`}>
                        <div className="grm-info__tabs">
                            <button
                                className={`grm-info__tab${this.state.grmSelectTab === 5 ? ' active' : ''}`}
                                onClick={() => {
                                    this.setState({preloading: true, grmSelectTab: 5}, () => {
                                        this.updateGrmItemInfo(this.state.currentGrmData);
                                    });
                                }}
                            >5 мин.
                            </button>
                            <button
                                className={`grm-info__tab${this.state.grmSelectTab === 10 ? ' active' : ''}`}
                                onClick={() => {
                                    this.setState({preloading: true, grmSelectTab: 10}, () => {
                                        this.updateGrmItemInfo(this.state.currentGrmData);
                                    });
                                }}
                            >10 мин.
                            </button>
                            <button
                                className={`grm-info__tab${this.state.grmSelectTab === 15 ? ' active' : ''}`}
                                onClick={() => {
                                    this.setState({preloading: true, grmSelectTab: 15}, () => {
                                        this.updateGrmItemInfo(this.state.currentGrmData);
                                    });
                                }}
                            >15 мин.
                            </button>
                        </div>
                        <div className="grm-info__clients">
                            <div>
                                <div className="grm-info__sub-title">Клієнти (кількість)</div>
                                <div
                                    className="grm-info__sub-desc">{new Intl.NumberFormat('uk-UK').format(this.state.grmData.totalCount)}</div>
                            </div>
                            <div>
                                <div className="grm-info__sub-title">Абонплата (грн.)</div>
                                <div
                                    className="grm-info__sub-desc">{new Intl.NumberFormat('uk-UK').format(this.state.grmData.totalAmount)}</div>
                            </div>
                        </div>
                        <ul className="grm-info__list">
                            {this.state.grmData.list ? this.state.grmData.list.map((item, index) => (
                                <ItemGrmInfo key={index} data={item}/>)) : ''}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default ListGrm;