import './Pagination.css';
import {ReactComponent as ArrowIcon} from "../../../images/arrow.svg";

function Pagination({contentPerPage, currentPage, changePage, totalLength}) {
    const
        pages = Math.ceil(totalLength / contentPerPage),
        pagesEls = [];

    if ((totalLength / contentPerPage) <= 6) {
        for (let i = 0; i < pages; i++) {
            pushElement(pagesEls, i);
        }
    } else {
        for (let i = currentPage - 1; i < pages; i++) {
            if (i <= currentPage + 4) pushElement(pagesEls, i);
        }
    }

    function pushElement(array, i) {
        array.push(
            <li key={i} className={`pagination__item${currentPage === i + 1 ? ' active' : ''}`}>
                <a href="/" className="pagination__link" onClick={(e) => {
                    e.preventDefault();
                    changePage(i + 1);
                }}>{i + 1}</a>
            </li>
        );
    }

    function prevHandler(e) {
        e.preventDefault();
        if (currentPage > 1) {
            changePage(--currentPage);
        }
    }

    function nextHandler(e) {
        e.preventDefault();
        if (currentPage < pages) {
            changePage(++currentPage);
        }
    }

    if (totalLength > contentPerPage) return (
        <div className="pagination__wrap">
            <div className="pagination__pages">
                {currentPage === 1 ? currentPage : (currentPage - 1) * contentPerPage + 1}
                -
                {contentPerPage * currentPage <= totalLength ?
                    contentPerPage * currentPage : totalLength} из {totalLength}
            </div>
            <ul className="pagination">
                <li className={`pagination__item prev${currentPage === 1 ? ' disabled' : ''}`}>
                    <a href="/" className="pagination__link" onClick={prevHandler}><ArrowIcon/></a>
                </li>
                {pagesEls.map(el => el)}
                <li className={`pagination__item next${currentPage === pages ? ' disabled' : ''}`}>
                    <a href="/" className="pagination__link" onClick={nextHandler}><ArrowIcon/></a>
                </li>
            </ul>
        </div>
    )
}

export default Pagination;