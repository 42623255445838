import React from 'react';
import Wrapper from "../../components/Wrapper/Wrapper";
import Navbar from "../../components/UI/Navbar/Navbar";
import HaveBtnHead from "../../components/HaveBtnHead/HaveBtnHead";
import {ReactComponent as EditIcon} from "../../images/edit_icon.svg";
import {ReactComponent as TrashIcon} from "../../images/trash.svg";
import Table from "../../components/UI/Table/Table";
import Pagination from "../../components/UI/Pagination/Pagination";
import UserImg from '../../images/user.png';
import AddUserModal from "../../components/Modals/UserModals/AddUserModal";
import axios from "axios";
import Api from "../../Api/Api";
import DangerModal from "../../components/Modals/DangerModal/DangerModal";
import UpdateUserModal from "../../components/Modals/UserModals/UpdateUserModal";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import RemoveToken from "../../Api/RemoveToken";
import {seo} from "../../helpers/seo";

toastr.options = {
    "closeButton": false,
    "debug": false,
    "newestOnTop": false,
    "progressBar": true,
    "positionClass": "toast-top-right",
    "preventDuplicates": false,
    "onclick": null,
    "showDuration": "300",
    "hideDuration": "1000",
    "timeOut": "5000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
};

class Users extends React.Component {
    state = {
        openedModal: false,
        page: 1,
        tableData: {
            head: ['Фото', 'Ім\'я прізвище', 'Посада', 'Номер телефону', 'Email', 'Права доступу', 'Ред', ''],
            body: [],
            allData: [],
            bodyLength: 0
        },
        dangerOpened: false,
        currentUser: {},
        updateOpened: false,
        preloading: true
    }
    contentPerPage = 10

    editUserHandler(userData) {
        this.setState({
            currentUser: userData,
            updateOpened: true
        });
    }

    removeUserHandler(userData) {
        this.setState({
            currentUser: userData,
            dangerOpened: true
        })
    }

    removeUser = () => {
        axios({
            method: 'post',
            url: Api.link + 'user/delete',
            data: {
                token: localStorage.getItem('token'),
                phone: localStorage.getItem('phone'),
                id: this.state.currentUser.phone.replace(/\D+/g, ''),
            }
        }).then(res => {
            if (res.data.success) {
                this.setState({
                    dangerOpened: false,
                    currentUser: {},
                    page: 1
                });
                this.updateUsers();
                this.updatePagination(1);
                toastr.clear();
                toastr.success('Користувач успішно видалений');
                if (this.state.currentUser.phone === localStorage.getItem('phone')) this.props.logoutFn();
            }
        }).catch(RemoveToken);
    }
    updateUsers = () => {
        this.setState({preloading: true});
        axios({
            method: 'post',
            url: Api.link + 'user/list',
            data: {
                phone: localStorage.getItem('phone'),
                token: localStorage.getItem('token')
            }
        }).then(res => {
            if (res.data.success) {
                let users = [];
                res.data.data.forEach(user => {
                    users.push([
                        {img: UserImg},
                        user.name,
                        user.job_title,
                        user.phone,
                        user.email,
                        +user.level === 1 ? 'Менеджер' : 'Адміністратор',
                        {
                            icon: <EditIcon/>,
                            value: '',
                            cb: this.editUserHandler.bind(this, user),
                            customStyles: {
                                cursor: 'pointer',
                            }
                        },
                        {
                            icon: <TrashIcon/>,
                            value: '',
                            cb: this.removeUserHandler.bind(this, user),
                            customStyles: {
                                cursor: 'pointer'
                            }
                        },
                    ]);
                });
                this.setState({
                    tableData: {
                        ...this.state.tableData,
                        body: users.slice(0, this.contentPerPage),
                        allData: users,
                        bodyLength: users.length
                    },
                    preloading: false
                })
            }
        }).catch(RemoveToken);
    }
    updatePagination = (page) => {
        const lastIndex = page * this.contentPerPage;
        const firstIndex = lastIndex - this.contentPerPage;

        this.setState({
            tableData: {
                ...this.state.tableData,
                body: this.state.tableData.allData.slice(firstIndex, lastIndex)
            }
        });
    }

    componentDidMount() {
        this.updateUsers();
        seo({
            title: 'Venbest Map - Users'
        });
    }

    render() {
        return (
            <Wrapper customClass="main">
                <Navbar userType={this.props.userInfo.level} page={this.props.currentPage} logoutCb={this.props.logoutFn}/>
                <div className="content grm-content">
                    <div className="content__wrap">
                        <HaveBtnHead
                            userInfo={this.props.userInfo}
                            title="Користувачі"
                            btnCb={() => this.setState({openedModal: true})}/>
                        <Table data={this.state.tableData} customClass="main__table" preloading={this.state.preloading}/>
                    </div>
                    <Pagination
                        contentPerPage={this.contentPerPage}
                        currentPage={this.state.page}
                        changePage={(val) => {
                            this.setState({page: val});
                            this.updatePagination(val);
                        }}
                        totalLength={this.state.tableData.bodyLength}/>
                </div>
                <AddUserModal
                    opened={this.state.openedModal}
                    closeCb={() => this.setState({openedModal: false})}
                    addCb={this.updateUsers}
                    updateCb={this.updatePagination}
                />
                <UpdateUserModal
                    opened={this.state.updateOpened}
                    closeCb={() => this.setState({updateOpened: false})}
                    addCb={this.updateUsers}
                    changeThisUser={this.props.changeThisUser}
                    userData={this.state.currentUser}
                    clearData={() => this.setState({currentUser: {}})}
                    updateData={(key, val) => {
                        const currentUser = {
                            ...this.state.currentUser
                        };
                        currentUser[key] = val;
                        this.setState({currentUser});
                    }}
                />
                <DangerModal
                    opened={this.state.dangerOpened}
                    closeCb={() => this.setState({dangerOpened: false})}
                    acceptCb={this.removeUser}
                    title="Ви дійсно бажаєте видалити цього користувача?"
                />
            </Wrapper>
        );
    }
}

export default Users;