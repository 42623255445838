import React from 'react';
import Wrapper from "../../components/Wrapper/Wrapper";
import Navbar from "../../components/UI/Navbar/Navbar";
import MainHead from "../../components/MainHead/MainHead";
import MainConsole from "../../components/MainConsole/MainConsole";
import GrmModal from "../../components/Modals/GrmModal/GrmModal";
import {seo} from "../../helpers/seo";
import axios from "axios";
import Api from "../../Api/Api";
import RemoveToken from "../../Api/RemoveToken";

class Home extends React.Component {
    state = {
        openedGrmModal: false,
        grmModalDate: {},
        gmrFullList: [],
        gmrList: [],
        citiesOptions: [],
        currentCity: null
    }

    openGrmModal = value => {
        this.setState({
            openedGrmModal: true,
            grmModalDate: value
        })
    };

    changeCurrentCity = cityOption => {
        this.setState({'currentCity': cityOption});
        localStorage.setItem('main-page-city', JSON.stringify(cityOption))
    }

    componentDidMount() {
        seo({
            title: 'Venbest Map - Home'
        });

        axios({
            method: 'post',
            url: Api.link + 'gmr/list',
            data: {
                phone: localStorage.getItem('phone'),
                token: localStorage.getItem('token')
            }
        }).then(res => {
            if (res.data.success) {
                let cityList = [];

                res.data.data.forEach(gmr => {
                    cityList.push(gmr.city);
                });

                this.setState({'gmrFullList': res.data.data});

                cityList = [...new Set(cityList)];
                let cityOptions = [
                    {value: "All", label: "Все"}
                ];
                cityList.forEach(item => cityOptions.push({value: item, label: item}));
                this.setState({citiesOptions: cityOptions}, () => {
                    this.setDefaultCity();
                });

            }
        }).catch(RemoveToken);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.currentCity !== prevState.currentCity) {
            if (this.state.currentCity !== null && this.state.currentCity.value !== 'All') {
                const filteredList = this.state.gmrFullList.filter(item => item.city === this.state.currentCity.value);
                this.setState({ gmrList: filteredList });
            } else {
                this.setState({ gmrList: this.state.gmrFullList });
            }
        }
    }

    setDefaultCity = () => {
        if (this.state.currentCity === null) {

            let storedCity = localStorage.getItem('main-page-city');

            if (storedCity) {
                storedCity = JSON.parse(storedCity);

                let foundedCityKey = this.state.citiesOptions.findIndex(obj => obj.value === storedCity.value);
                if (foundedCityKey) {
                    this.setState({currentCity: this.state.citiesOptions[foundedCityKey]});
                } else {
                    this.setState({currentCity: this.state.citiesOptions[0]});
                    localStorage.removeItem('main-page-city');
                }
            } else {
                this.setState({currentCity: this.state.citiesOptions[0]});
            }
        }
    }

    render() {
        return (
            <Wrapper customClass="main">
                <Navbar page={this.props.currentPage} userType={this.props.userInfo.level} logoutCb={this.props.logoutFn}/>
                <div className="content">
                    <MainHead userInfo={this.props.userInfo}
                              title="Головна"
                              selectCb={this.openGrmModal}
                              grmFullList={this.state.grmFullList}
                              citiesOptions={this.state.citiesOptions}
                              currentCity={this.state.currentCity}
                              changeCity={this.changeCurrentCity}
                              haveSelect={true}/>
                    <MainConsole userType={this.props.userInfo.level} gmrList={this.state.gmrList} currentCity={this.state.currentCity}/>
                </div>
                <GrmModal
                    opened={this.state.openedGrmModal}
                    data={this.state.grmModalDate}
                    clearData={() => this.setState({grmModalDate: {}})}
                    closeCb={() => {
                        this.setState({openedGrmModal: false})
                    }}
                />
            </Wrapper>
        );
    }
}

export default Home;