import './HaveBtnHead.css';
import User from "../UI/User/User";
import {ReactComponent as AddIcon} from "../../images/add.svg";
import UserImg from '../../images/user.png';

function HaveBtnHead({title, btnCb, titleCustomStyle, userInfo}) {
    return(
        <div className="btn-have-head">
            <div className="btn-have-head__wrap">
                <div className="btn-have-head__title" style={(titleCustomStyle)}>{title}</div>
                <button onClick={btnCb} className="btn-have-head__btn btn btn--add">
                    <AddIcon/>
                </button>
            </div>
            <User name={userInfo.name} position={userInfo.job_title}  image={UserImg} />
        </div>
    )
}

export default HaveBtnHead;