import React from "react";
import Select from "react-select";
import './MainConsole.css';
import ConsoleGmr from "./ConsoleGmr/ConsoleGmr";
import Arrow from '../../images/bt_arrow.svg';
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxGl from '!mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import axios from "axios";
import Api from "../../Api/Api";
import RemoveToken from "../../Api/RemoveToken";
import turfCircle from '@turf/circle';
import EditGmr from "./EditGmr";
import toastr from "toastr";

class MainConsole extends React.Component {
    state = {
        tabType: 'clients',
        lng: 30.542293,
        lat: 50.467119,
        zoom: 10.54,
        trafficJams: '830',
        loadedClusters: false,
        edit: false,
        geofence: {},
        currentGeoMark: {},
        changedGeoMark: [],
    }

    constructor(props) {
        super(props);
        this.mapContainer = React.createRef();
        this.map = React.createRef();
        mapboxGl.accessToken = 'pk.eyJ1IjoiZG9kb3RhcCIsImEiOiJjbGNleWkwd2wwZTBpM3JuMDliaTczMmdkIn0.Stdk96F101S91AMX_S5feA';
    }

    componentDidMount() {
        if (this.map.current) return;
        this.map.current = new mapboxGl.Map({
            container: this.mapContainer.current,
            style: 'mapbox://styles/dodotap/clceyfxrx001a14qwip9b4ea0',
            center: [this.state.lng, this.state.lat],
            zoom: this.state.zoom,
            resize: true
        });
        this.map.current.on('load', () => {
            this.map.current.addSource('earthquakes', {
                type: 'geojson',
                data: +this.props.userType === 1 ?
                    'https://api2-fbsswv5kuq-uc.a.run.app/map/gmr/list/geojson' :
                    'https://api2-fbsswv5kuq-uc.a.run.app/map/clients/list/geojson',
                cluster: true,
                clusterMaxZoom: 14, // Max zoom to cluster points on
                clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
                clusterProperties: {
                    sum: ["+", ["get", "amount"]],
                },
            });
            this.map.current.on('idle', () => {
                if (!this.state.loadedClusters) this.setState({loadedClusters: true});
            });
            this.clientsInitial();

            if (+this.props.userType === 1) {
                const radius = this.state.trafficJams * 5 / 1000;
                const marks = [];
                this.props.gmrList.forEach(gmr => {
                    const
                        center = [gmr.longitude, gmr.latitude],
                        options = {steps: 64, units: 'kilometers', properties: {foo: 'bar'}},
                        circle = turfCircle(center, radius, options);

                    circle.properties.company = gmr.company;
                    circle.properties.id = gmr.id;
                    circle.properties.name = gmr.name;
                    circle.properties.phone = gmr.phone;
                    circle.properties.city = gmr.city;
                    circle.properties.coordinates = [gmr.longitude, gmr.latitude];

                    marks.push(circle);

                });
                this.createGmrMark(marks);
            }
        });

        this.map.current.on('click', change_geofence.bind(this));

        function change_geofence(event) {
            if (!this.state.edit) return;
            const
                coordinates = event.lngLat,
                lng = coordinates.lng,
                lat = coordinates.lat;

            const radius = this.state.trafficJams * 5 / 1000;
            const marks = [];
            const
                center = [lng, lat],
                options = {steps: 64, units: 'kilometers', properties: {foo: 'bar'}},
                circle = turfCircle(center, radius, options);

            circle.properties.company = this.state.currentGeoMark.companyName;
            circle.properties.id = this.state.currentGeoMark.id;
            circle.properties.name = this.state.currentGeoMark.name;
            circle.properties.phone = this.state.currentGeoMark.phoneNumber;
            circle.properties.city = this.state.currentGeoMark.city;
            circle.properties.coordinates = [lng, lat];

            marks.push(circle);

            for (let i = 0; i < 1000; i++) {
                if (this.map.current.getSource(`gmr-mark-${i}`)) {
                    this.map.current.removeLayer(`gmr-border-mark-${i}`);
                    this.map.current.removeLayer(`gmr-circle-mark-${i}`);
                    this.map.current.removeLayer(`gmr-point-mark-${i}`);
                    this.map.current.removeSource(`gmr-mark-${i}`);
                    this.map.current.removeSource(`gmr-point-${i}`);
                    i = 1000;
                }
            }

            this.createGmrMark(marks);

            this.map.current.flyTo({
                center: [lng, lat],
                zoom: 11.5,
            });
            this.setState({changedGeoMark: [lng, lat]});
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (+this.props.userType === 1 && this.props.gmrList !== prevProps.gmrList) {
            this.removeGmrMark();

            const radius = this.state.trafficJams * 5 / 1000;
            const marks = [];
            this.props.gmrList.forEach(gmr => {
                const
                    center = [gmr.longitude, gmr.latitude],
                    options = {steps: 64, units: 'kilometers', properties: {foo: 'bar'}},
                    circle = turfCircle(center, radius, options);

                circle.properties.company = gmr.company;
                circle.properties.id = gmr.id;
                circle.properties.name = gmr.name;
                circle.properties.phone = gmr.phone;
                circle.properties.city = gmr.city;
                circle.properties.coordinates = [gmr.longitude, gmr.latitude];

                marks.push(circle);

            });
            this.createGmrMark(marks);
        }

        if (this.props.currentCity !== prevState.currentCity) {
            if (this.props.currentCity !== null && this.props.currentCity.value !== 'All' && this.props.gmrList.length) {
                const firstGmr = this.props.gmrList[0];
                this.map.current.flyTo({
                    center: [firstGmr.longitude, firstGmr.latitude],
                    zoom: this.state.zoom,
                });
            }
        }

        if (prevState.tabType === this.state.tabType) return;
        this.clearClusters();
        if (this.state.tabType === 'clients') {
            this.clientsInitial();
        } else {
            this.amountInitial();
        }
    }

    saveInfoChangesHandler = (e) => {
        e.preventDefault();
        const gmrData = {
            // name: data.name,
            phone: localStorage.getItem('phone'),
            // company: data.company,
            // city: data.city,
            token: localStorage.getItem('token'),
            id: this.state.currentGeoMark.id,
            longitude: this.state.changedGeoMark[0],
            latitude: this.state.changedGeoMark[1]
        };

        axios({
            method: 'post',
            url: Api.link + 'gmr/edit',
            data: gmrData
        }).then(res => {
            if (res.data.success) {
                this.setState({edit: false});
                toastr.clear();
                toastr.success('ГМР успішно змінено');
            }
        }).catch((error) => console.log(error));
    }

    removeInfoChangesHandler = (e) => {
        e.preventDefault();

        const radius = this.state.trafficJams * 5 / 1000;
        const marks = [];
        const
            center = [this.state.currentGeoMark.longitude, this.state.currentGeoMark.latitude],
            options = {steps: 64, units: 'kilometers', properties: {foo: 'bar'}},
            circle = turfCircle(center, radius, options);

        circle.properties.id = this.state.currentGeoMark.id;
        circle.properties.company = this.state.currentGeoMark.companyName;
        circle.properties.name = this.state.currentGeoMark.name;
        circle.properties.phone = this.state.currentGeoMark.phoneNumber;
        circle.properties.city = this.state.currentGeoMark.city;
        circle.properties.coordinates = [this.state.currentGeoMark.longitude, this.state.currentGeoMark.latitude];

        marks.push(circle);
        this.createGmrMark(marks);

        this.map.current.flyTo({
            center: [this.state.currentGeoMark.longitude, this.state.currentGeoMark.latitude],
            zoom: 11.5,
        });
        this.setState({
            edit: false,
            changedGeoMark: [this.state.currentGeoMark.longitude, this.state.currentGeoMark.latitude]
        });
    }
    createGmrMark = (gmrMarks, working = true) => {
        this.clearClusters(true);
        if (gmrMarks && working) {
            gmrMarks.forEach((mark, index) => {
                if (!this.map.current.getSource(`gmr-mark-${index}`)) this.map.current.addSource(`gmr-mark-${index}`, {
                    'type': 'geojson',
                    'data': mark
                });

                if (!this.map.current.getSource(`gmr-point-${index}`)) this.map.current.addSource(`gmr-point-${index}`, {
                    type: 'geojson',
                    data: {
                        "type": "Feature",
                        "geometry": {
                            "type": "Point",
                            "coordinates": mark.properties.coordinates
                        },
                        "properties": mark.properties
                    },
                });

                if (!this.map.current.getLayer(`gmr-circle-mark-${index}`)) this.map.current.addLayer({
                    'id': `gmr-circle-mark-${index}`,
                    'type': 'fill',
                    'source': `gmr-mark-${index}`,
                    'layout': {},
                    'paint': {
                        'fill-color': mark.properties.company === 'Venbest' ? '#EA4433' : '#1AC08F',
                        'fill-opacity': 0.25
                    }
                });

                if (!this.map.current.getLayer(`gmr-border-mark-${index}`)) this.map.current.addLayer({
                    'id': `gmr-border-mark-${index}`,
                    'type': 'line',
                    'source': `gmr-mark-${index}`,
                    'layout': {},
                    'paint': {
                        'line-color': mark.properties.company === 'Venbest' ? '#EA4433' : '#1AC08F',
                        'line-width': 2
                    }
                });

                if (!this.map.current.getLayer(`gmr-point-mark-${index}`)) this.map.current.addLayer({
                    id: `gmr-point-mark-${index}`,
                    type: 'circle',
                    source: `gmr-point-${index}`,
                    paint: {
                        'circle-color': '#FFFFFF',
                        'circle-radius': 2,
                        'circle-stroke-width': 4,
                        'circle-stroke-color': mark.properties.company === 'Venbest' ? '#EA4433' : '#1AC08F',
                    }
                });

                if (this.map.current.getLayer(`gmr-point-mark-${index}`)) this.map.current.on('click', `gmr-point-mark-${index}`, this.popupGmr);

            });
        }
    }
    removeGmrMark = () => {
        this.clearClusters(true);
    }
    zoomToGmrMark = (coordinates, zoom, gmrMarks) => {
        gmrMarks.forEach((gmr, index) => {
            if (gmr.longitude !== coordinates[0] && gmr.latitude !== coordinates[1]) {
                this.map.current.removeLayer(`gmr-circle-mark-${index}`);
                this.map.current.removeLayer(`gmr-border-mark-${index}`);
                this.map.current.removeLayer(`gmr-point-mark-${index}`);
                this.map.current.removeSource(`gmr-mark-${index}`);
                this.map.current.removeSource(`gmr-point-${index}`);
            } else {
                this.setState({currentGeoMark: gmr});
            }
        });
        this.map.current.easeTo({
            center: coordinates,
            zoom
        });
    }
    refreshGmrMark = (coordinates, gmrMarks) => {
        this.clearClusters(true, gmrMarks.length);
        gmrMarks.forEach((mark, index) => {
            if (mark.properties.coordinates[0] === coordinates[0] && mark.properties.coordinates[1] === coordinates[1]) {
                if (!this.map.current.getSource(`gmr-mark-${index}`)) this.map.current.addSource(`gmr-mark-${index}`, {
                    'type': 'geojson',
                    'data': mark
                });
                if (!this.map.current.getSource(`gmr-point-${index}`)) this.map.current.addSource(`gmr-point-${index}`, {
                    type: 'geojson',
                    data: {
                        "type": "Feature",
                        "geometry": {
                            "type": "Point",
                            "coordinates": mark.properties.coordinates
                        },
                        "properties": mark.properties
                    },
                });
                if (!this.map.current.getLayer(`gmr-circle-mark-${index}`)) this.map.current.addLayer({
                    'id': `gmr-circle-mark-${index}`,
                    'type': 'fill',
                    'source': `gmr-mark-${index}`,
                    'layout': {},
                    'paint': {
                        'fill-color': mark.properties.company === 'Venbest' ? '#EA4433' : '#1AC08F',
                        'fill-opacity': 0.25
                    }
                });
                if (!this.map.current.getLayer(`gmr-border-mark-${index}`)) this.map.current.addLayer({
                    'id': `gmr-border-mark-${index}`,
                    'type': 'line',
                    'source': `gmr-mark-${index}`,
                    'layout': {},
                    'paint': {
                        'line-color': mark.properties.company === 'Venbest' ? '#EA4433' : '#1AC08F',
                        'line-width': 2
                    }
                });
                if (!this.map.current.getLayer(`gmr-point-mark-${index}`)) this.map.current.addLayer({
                    id: `gmr-point-mark-${index}`,
                    type: 'circle',
                    source: `gmr-point-${index}`,
                    paint: {
                        'circle-color': '#FFFFFF',
                        'circle-radius': 2,
                        'circle-stroke-width': 4,
                        'circle-stroke-color': mark.properties.company === 'Venbest' ? '#EA4433' : '#1AC08F',
                    }
                });
                if (this.map.current.getLayer(`gmr-point-mark-${index}`)) this.map.current.on('click', `gmr-point-mark-${index}`, this.popupGmr);
            }
        });
    }
    backClusters = (gmrMarks) => {
        this.createGmrMark(gmrMarks);
    }
    clearClusters = (onlyGmrMark = false, countMark = 1000) => {
        if (onlyGmrMark) {
            for (let i = 0; i < countMark; i++) {
                if (this.map.current.getSource(`gmr-mark-${i}`)) {
                    this.map.current.removeLayer(`gmr-circle-mark-${i}`);
                    this.map.current.removeLayer(`gmr-border-mark-${i}`);
                    this.map.current.removeLayer(`gmr-point-mark-${i}`);
                    this.map.current.removeSource(`gmr-mark-${i}`);
                    this.map.current.removeSource(`gmr-point-${i}`);
                } else {
                    if (countMark === 1000) i = countMark
                }
            }
        } else {
            if (this.map.current.getLayer('clusters')) this.map.current.removeLayer('clusters');
            if (this.map.current.getLayer('cluster-count')) this.map.current.removeLayer('cluster-count');
            if (this.map.current.getLayer('unclustered-point')) this.map.current.removeLayer('unclustered-point');
        }
    }
    popupGmr = (e) => {
        const coordinates = e.features[0].geometry.coordinates.slice();
        const id = e.features[0].properties.id;
        const name = e.features[0].properties.name;
        const company = e.features[0].properties.company;
        const phone = e.features[0].properties.phone;
        const city = e.features[0].properties.city;

        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        new mapboxGl.Popup()
            .setLngLat(coordinates)
            .setHTML(
                `ID: ${id}<br>Місто: ${city}<br>Назва: ${name}<br>Компанія: ${company}<br>Номер: ${phone}`
            )
            .addTo(this.map.current);
    }
    clientsInitial = () => {
        if (!this.map.current.getLayer('clusters')) this.map.current.addLayer({
            id: 'clusters',
            type: 'circle',
            source: 'earthquakes',
            filter: ['has', 'point_count'],
            paint: {
                'circle-color': [
                    'step',
                    ['get', 'point_count'],
                    '#51bbd6',
                    100,
                    '#f1f075',
                    750,
                    '#f28cb1'
                ],
                'circle-radius': [
                    'step',
                    ['get', 'point_count'],
                    20,
                    100,
                    30,
                    750,
                    40
                ]
            }
        });

        if (!this.map.current.getLayer('cluster-count')) this.map.current.addLayer({
            id: 'cluster-count',
            type: 'symbol',
            source: 'earthquakes',
            filter: ['has', 'point_count'],
            layout: {
                'text-field': ['get', 'point_count_abbreviated'],
                'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                'text-size': 12
            }
        });

        if (!this.map.current.getLayer('unclustered-point')) this.map.current.addLayer({
            id: 'unclustered-point',
            type: 'circle',
            source: 'earthquakes',
            filter: ['!', ['has', 'point_count']],
            paint: {
                'circle-color': '#11b4da',
                'circle-radius': 4,
                'circle-stroke-width': 1,
                'circle-stroke-color': '#fff'
            }
        });

        this.onClickClusterInitial();
    }

    amountInitial = () => {
        if (!this.map.current.getLayer('clusters')) this.map.current.addLayer({
            id: 'clusters',
            type: 'circle',
            source: 'earthquakes',
            filter: ['has', 'sum'],
            paint: {
                'circle-color': [
                    'step',
                    ['get', 'sum'],
                    '#51BBD6',
                    -1,
                    '#86CDB6',
                    1000,
                    '#BBDF96',
                    10000,
                    '#F1F075',
                    100000,
                    '#F1CF89',
                    1000000,
                    '#F1AE9D',
                    10000000,
                    '#F28CB1',
                ],
                'circle-radius': [
                    'step',
                    ['get', 'sum'],
                    5.7,
                    -1,
                    11.4,
                    1000,
                    17.1,
                    10000,
                    22.8,
                    100000,
                    28.6,
                    1000000,
                    34.3,
                    10000000,
                    40
                ],
            }
        });

        if (!this.map.current.getLayer('cluster-count')) this.map.current.addLayer({
            id: 'cluster-count',
            type: 'symbol',
            source: 'earthquakes',
            filter: ['has', 'sum'],
            layout: {
                'text-field': ['get', 'sum'],
                'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                'text-size': 12
            }
        });

        if (!this.map.current.getLayer('unclustered-point')) this.map.current.addLayer({
            id: 'unclustered-point',
            type: 'circle',
            source: 'earthquakes',
            filter: ['!', ['has', 'point_count']],
            paint: {
                'circle-color': '#11b4da',
                'circle-radius': 4,
                'circle-stroke-width': 1,
                'circle-stroke-color': '#fff'
            }
        });

        this.onClickClusterInitial();
    }

    onClickClusterInitial = () => {
        this.map.current.on('click', 'clusters', (e) => {
            const features = this.map.current.queryRenderedFeatures(e.point, {
                layers: ['clusters']
            });
            const clusterId = features[0].properties.cluster_id;
            this.map.current.getSource('earthquakes').getClusterExpansionZoom(
                clusterId,
                (err, zoom) => {
                    if (err) return;

                    this.map.current.easeTo({
                        center: features[0].geometry.coordinates,
                        zoom: zoom
                    });
                }
            );
        });
        if (+this.props.userType === 1) {
            this.map.current.on('click', 'unclustered-point', this.popupGmr);
        } else {
            this.map.current.on('click', 'unclustered-point', (e) => {

                const coordinates = e.features[0].geometry.coordinates.slice();
                const amount = e.features[0].properties.amount;
                const name = e.features[0].properties.name;
                const owner = e.features[0].properties.owner;
                const id = e.features[0].properties.id;
                const address = e.features[0].properties.address;

                while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                    coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                }

                new mapboxGl.Popup()
                    .setLngLat(coordinates)
                    .setHTML(
                        `ID: ${id}<br>Назва: ${name}<br>Адреса: ${address}<br>Компанія: ${owner}<br>Абонплата: ${amount}`
                    )
                    .addTo(this.map.current);
            });
        }
        this.map.current.on('mouseenter', 'clusters', () => {
            this.map.current.getCanvas().style.cursor = 'pointer';
        });
        this.map.current.on('mouseleave', 'clusters', () => {
            this.map.current.getCanvas().style.cursor = '';
        });
    }

    render() {
        const
            customStyles = {
                dropdownIndicator: () => ({
                    display: 'none'
                }),
                indicatorsContainer: () => ({
                    display: 'none'
                }),
                container: (provided) => ({
                    ...provided,
                    maxWidth: '210px',
                    width: '100%',
                    height: '34px'
                }),
                control: (provided) => ({
                    ...provided,
                    border: '1px solid var(--text-1)',
                    boxShadow: '0 0 15px rgba(0, 0, 0, 0.1)',
                    borderRadius: '30px',
                    cursor: 'pointer',
                    minHeight: '34px',
                    position: 'relative',
                    zIndex: '2',
                    '&:hover': {
                        borderColor: 'var(--text-1)',
                    },
                    '&:after': {
                        content: `url(${Arrow})`,
                        position: 'absolute',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        right: '15px'
                    }
                }),
                input: (provided) => ({
                    ...provided,
                }),
                placeholder: (provided) => ({
                    ...provided,
                    fontSize: '16px',
                    color: 'var(--text-1)',
                    transition: 'all .3s',
                    height: '100%',
                    width: '100%',
                    paddingLeft: '17px',
                    lineHeight: '32px',
                }),
                menu: (provided) => ({
                    ...provided,
                    margin: '0',
                    top: '0',
                    paddingTop: '34px',
                    borderRadius: '30px',
                    background: 'var(--white)',
                    border: '1px solid var(--text-2)',
                    boxShadow: '0 0 15px rgba(0, 0, 0, 0.1)',
                    overflow: 'hidden',
                }),
                menuList: (provided) => ({
                    ...provided,
                    paddingBottom: '0',
                    maxHeight: '410px'
                }),
                option: (provided) => ({
                    ...provided,
                    transition: 'all .3s',
                    background: 'var(--white)',
                    cursor: 'pointer',
                    height: '40px',
                    paddingLeft: '18px',
                    fontSize: '16px',
                    color: 'var(--text-1)',
                    borderBottom: '1px solid var(--divider)',
                    '&:nth-last-of-type(1)': {
                        borderBottom: 'none'
                    },
                    '&:hover': {
                        background: 'rgba(255, 104, 83, 0.1)'
                    }
                }),
                valueContainer: (provided) => ({
                    ...provided,
                    padding: '0',
                    height: '32px',
                }),
                singleValue: (provided) => ({
                    ...provided,
                    paddingLeft: '17px',
                }),
            },
            selectOptions = [
                {value: '830', label: '1 бал - 50км/год'},
                {value: '750', label: '2 бали - 45км/год'},
                {value: '666', label: '3 бали - 40км/год'},
                {value: '583', label: '4 бали - 35км/год'},
                {value: '500', label: '5 балів - 30км/год'},
                {value: '416', label: '6 балів - 25км/год'},
                {value: '333', label: '7 балів - 20км/год'},
                {value: '250', label: '8 балів - 15км/год'},
                {value: '167', label: '9 балів - 10км/год'},
                {value: '83', label: '10 балів - 5км/год'}
            ];

        return (
            <div className="main-console">
                <div className="main-console__wrap">
                    {
                        +this.props.userType === 1 ? ''
                            :
                            <div className="console">
                                <div className="console__controls">
                                    <div className={`console__tabs ${this.state.tabType}`}>
                                        <button
                                            className={`console__tab${this.state.tabType === 'clients' ? ' active' : ''}`}
                                            onClick={() => {
                                                this.setState({tabType: 'clients'})
                                            }}
                                        >
                                            Клієнти
                                        </button>
                                        <button
                                            className={`console__tab${this.state.tabType === 'amount' ? ' active' : ''}`}
                                            onClick={() => {
                                                this.setState({tabType: 'amount'})
                                            }}
                                        >
                                            Абонплата
                                        </button>
                                    </div>
                                    <Select
                                        className="console__traffic-jams"
                                        id="trafficJams"
                                        options={selectOptions}
                                        isSearchable={false}
                                        styles={customStyles}
                                        value={selectOptions.filter(option => option.value === this.state.trafficJams)}
                                        onChange={val => this.setState({trafficJams: val.value})}
                                        placeholder="Пробки"
                                    />
                                </div>
                                <ConsoleGmr
                                    ready={this.state.loadedClusters}
                                    edit={this.state.edit}
                                    setEdit={(val) => this.setState({edit: val})}
                                    turnOnCb={this.createGmrMark}
                                    turnOffCb={this.removeGmrMark}
                                    closeItemInfoFb={this.backClusters}
                                    onClickGmr={this.zoomToGmrMark}
                                    refreshGmrMark={this.refreshGmrMark}
                                    trafficJams={this.state.trafficJams}
                                    changedGeoMark={this.state.changedGeoMark}
                                    gmrList={this.props.gmrList}
                                />
                            </div>
                    }
                    <div ref={this.mapContainer}
                         className={`main-console__map${+this.props.userType === 1 ? ' map-manager' : ''}`}></div>
                    <EditGmr
                        state={this.state.edit}
                        changeState={val => this.setState({edit: val})}
                        saveInfoChangesHandler={this.saveInfoChangesHandler}
                        removeInfoChangesHandler={this.removeInfoChangesHandler}
                    />
                </div>
            </div>
        );
    }
}

export default MainConsole;