import React from "react";

class EditGmr extends React.Component {
    render() {
        return (
            <div className={`gmr-edit ${this.props.state ? ' visible' : ''}`}>
                <div className="gmr-edit__title">Перетягніть грм на нову адресу</div>
                <div className="gmr-edit__buttons">
                    <button className="gmr-edit__button btn btn--white" onClick={this.props.removeInfoChangesHandler}>Скасувати</button>
                    <button className="gmr-edit__button btn btn--success" onClick={this.props.saveInfoChangesHandler}>Зберегти</button>
                </div>
            </div>
        );
    }
}

export default EditGmr;