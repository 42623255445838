import {useState} from "react";
import './ConsoleGmr.css';
import ListGrm from "../ListGrm/ListGrm";

function ConsoleGmr({
                        trafficJams,
                        turnOnCb,
                        turnOffCb,
                        ready,
                        onClickGmr,
                        closeItemInfoFb,
                        refreshGmrMark,
                        edit,
                        setEdit,
                        changedGeoMark,
                        gmrList
                    }) {
    const [stateGrm, setStateGrm] = useState(false);
    return (
        <div className={`console__gmr${ready ? ' ready' : ''}`}>
            <label className="console__gmr-switch">
                <span className="console__gmr-label">ГМР</span>
                <input type="checkbox" onChange={(e) => {
                    setStateGrm(e.target.checked);
                    if (!e.target.checked) turnOffCb();
                    if (!e.target.checked) setEdit(e.target.checked);
                }}/>
                <span className="console__gmr-slider"></span>
            </label>
            <ListGrm
                onClickGmr={onClickGmr}
                ready={ready}
                edit={edit}
                setEdit={setEdit}
                turnOffCb={turnOffCb}
                turnOnCb={turnOnCb}
                opened={stateGrm}
                changedGeoMark={changedGeoMark}
                closeItemInfoFb={closeItemInfoFb}
                refreshGmrMark={refreshGmrMark}
                trafficJams={trafficJams}
                gmrList={gmrList}/>
        </div>
    );
}

export default ConsoleGmr;