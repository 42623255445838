import './Table.css';

function Table({data, customClass, preloading}) {
    const
        head = data.head,
        body = data.body;

    if (preloading) {
        return (
            <div className={`table__wrapper${customClass ? ' ' + customClass : ''}`}>
                <div className="load">
                    <hr/>
                    <hr/>
                    <hr/>
                    <hr/>
                </div>
            </div>
        )
    }

    if (body.length === 0) {
        return (
            <div className={`table__wrapper${customClass ? ' ' + customClass : ''}`}>
                <div className="table__not-found">Пусто</div>
            </div>
        )
    }

    return (
        <div className={`table__wrapper${customClass ? ' ' + customClass : ''}`}>
            <table className="table">
                <thead className="table__head">
                <tr>
                    {head.map((th, thIndex) => {
                        if (typeof (th) === 'object') {
                            const
                                icon = th.icon ? th.icon : '',
                                customStyles = th.customStyles ? th.customStyles : {},
                                cb = th.cb ? th.cb : () => {
                                };

                            return (<th onClick={cb} key={thIndex} style={(customStyles)}>{icon}{th.value}</th>);
                        } else {
                            return (<th key={thIndex}>{th}</th>);
                        }
                    })}
                </tr>
                </thead>
                <tbody className="table__body">
                {
                    body.map((tr, trIndex) => (
                        <tr key={trIndex}>
                            {
                                tr.map((td, tdIndex) => {
                                    if (typeof (td) === 'object') {
                                        if (td.img) {
                                            const
                                                customStyles = td.customStyles ? td.customStyles : {},
                                                cb = td.cb ? td.cb : () => {
                                                };

                                            return (<td onClick={cb} key={tdIndex}
                                                        style={(customStyles)}><img src={td.img} alt='img'/></td>);
                                        } else {
                                            const
                                                icon = td.icon ? td.icon : '',
                                                customStyles = td.customStyles ? td.customStyles : {},
                                                cb = td.cb ? td.cb : () => {
                                                };

                                            return (<td onClick={cb} key={tdIndex}
                                                        style={(customStyles)}>{icon}{td.value}</td>);
                                        }
                                    } else {
                                        return (<td key={tdIndex}>{td}</td>);
                                    }
                                })
                            }
                        </tr>
                    ))
                }
                </tbody>
            </table>
        </div>
    );
}

export default Table;