import SearchIcon from "../../images/search_icon.svg";
import BottomArrowIcon from "../../images/bt_arrow.svg";

const GrmInfo = {
    citiesOptions: [
        {value: 'Киев', label: 'Киев'},
        {value: 'Харьков', label: 'Харьков'},
        {value: 'Одесса', label: 'Одесса'},
        {value: 'Львов', label: 'Львов'},
        {value: 'Днепр', label: 'Днепр'},
        {value: 'Житомир', label: 'Житомир'},
        {value: 'Запорожье', label: 'Запорожье'},
        {value: 'Николаев', label: 'Николаев'},
        {value: 'Буковель', label: 'Буковель'},
        {value: 'Ужгород', label: 'Ужгород'},
        {value: 'Винница', label: 'Винница'},
        {value: 'Полтава', label: 'Полтава'},
        {value: 'Ивано-Франковск', label: 'Ивано-Франковск'},
        {value: 'Тернополь', label: 'Тернополь'},
        {value: 'Ровно', label: 'Ровно'},
        {value: 'Черновцы', label: 'Черновцы'},
        {value: 'Хмельницкий', label: 'Хмельницкий'},
        {value: 'Черкассы', label: 'Черкассы'},
        {value: 'Кропивницкий', label: 'Кропивницкий'},
        {value: 'Кременчуг', label: 'Кременчуг'},
        {value: 'Суммы', label: 'Суммы'},
        {value: 'Кривой Рог', label: 'Кривой Рог'},
        {value: 'Луцк', label: 'Луцк'},
        {value: 'Мукачево', label: 'Мукачево'},
    ],
    modals: {
        customStyles: {
            dropdownIndicator: () => ({
                display: 'none'
            }),
            indicatorsContainer: () => ({
                display: 'none'
            }),
            container: (provided) => ({
                ...provided,
                height: '50px',
                width: '100%',
            }),
            control: (provided, state, isValid) => ({
                ...provided,
                height: '100%',
                outline: 'none',
                background: 'var(--white)',
                border: '1px solid #9F9F9F',
                transition: 'all .5s',
                borderRadius: '10px',
                boxShadow: '0 0 8px rgba(0, 0, 0, 0.1)',
                cursor: 'pointer',
                position: 'relative',
                zIndex: 5,
                "&:hover": {},
                '&:before': {
                    content: `url(${SearchIcon})`,
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    left: '14px',
                    height: '23px'
                },
                '&:after': {
                    content: `url(${BottomArrowIcon})`,
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    right: '17px'
                }
            }),
            input: (provided) => ({
                ...provided,
                fontFamily: 'Roboto, sans-serif',
                paddingLeft: '40px',
                color: 'var(--text-1)',
            }),
            placeholder: (provided) => ({
                ...provided,
                fontFamily: 'Roboto, sans-serif',
                color: 'var(--text-2)',
                paddingLeft: '40px',
                fontSize: '18px'
            }),
            menu: (provided) => ({
                ...provided,
                margin: '0',
                top: '0',
                paddingTop: '50px',
                borderRadius: '10px',
                border: '1px solid #9F9F9F',
                background: 'var(--white)',
                boxShadow: '0 0 15px rgba(0, 0, 0, 0.1)',
                overflow: 'hidden',
                zIndex: 4
            }),
            menuList: (provided) => ({
                ...provided,
                padding: '0',
                maxHeight: '410px',
            }),
            option: (provided) => ({
                ...provided,
                transition: 'all .3s',
                background: 'var(--white)',
                cursor: 'pointer',
                paddingLeft: '18px',
                fontSize: '16px',
                color: 'var(--text-1)',
                borderBottom: '1px solid var(--divider)',
                '&:nth-last-of-type(1)': {
                    borderBottom: 'none'
                },
                '&:hover': {
                    background: 'rgba(255, 104, 83, 0.1)'
                }
            }),
            singleValue: (provided) => ({
                ...provided,
                paddingLeft: '40px',
            }),
        }
    }
};

const customStylesAddress = Object.assign({}, GrmInfo.modals.customStyles);

customStylesAddress.placeholder = (provided) => ({
    ...provided,
    fontFamily: 'Roboto, sans-serif',
    color: 'var(--text-1)',
    paddingLeft: '40px',
    fontSize: '18px'
});
customStylesAddress.control = (provided) => ({
    ...provided,
    height: '100%',
    outline: 'none',
    background: 'var(--white)',
    border: '1px solid #9F9F9F',
    transition: 'all .5s',
    borderRadius: '10px',
    boxShadow: '0 0 8px rgba(0, 0, 0, 0.1)',
    cursor: 'pointer',
    position: 'relative',
    zIndex: '3',
    "&:hover": {},
    '&:before': {
        content: `url(${SearchIcon})`,
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        left: '14px',
        height: '23px'
    },
    '&:after': {
        content: `url(${BottomArrowIcon})`,
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        right: '17px'
    }
});
customStylesAddress.menu = (provided) => ({
    ...provided,
    margin: '0',
    top: '0',
    paddingTop: '50px',
    borderRadius: '10px',
    border: '1px solid #9F9F9F',
    background: 'var(--white)',
    boxShadow: '0 0 15px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
    zIndex: 2
});

GrmInfo.modals.customStylesAddress = customStylesAddress;

export default GrmInfo;