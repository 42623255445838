import './ItemGrm.css';
import {ReactComponent as BriefcaseIcon} from "../../../../images/briefcase.svg";

function ItemGrm({data, cb}) {
    const
        img = data.img,
        name = data.name,
        phoneNumber = data.phoneNumber,
        companyName = data.companyName;

    return(
        <li className="list-grm__item" onClick={() => { cb(data) }}>
            <div className="list-grm__info">
                <img src={img} alt={name + '_icon'} className="list-grm__info-img"/>
                <div className="list-grm__info-content">
                    <div className="list-grm__info-name">{name}</div>
                    <div className="list-grm__info-number">{phoneNumber}</div>
                </div>
            </div>
            <div className="list-grm__company">
                <BriefcaseIcon /> {companyName}
            </div>
        </li>
    );
}

export default ItemGrm;