import './GrmConsole.css';
import Select from "react-select";
import React from "react";
import SearchIcon from "../../images/search_icon.svg";
import BottomArrowIcon from "../../images/bt_arrow.svg";
import {ReactComponent as CloseIcon} from "../../images/close_icon.svg";
import toastr from "toastr";
import 'toastr/build/toastr.min.css';

toastr.options = {
    "closeButton": false,
    "debug": false,
    "newestOnTop": false,
    "progressBar": true,
    "positionClass": "toast-top-right",
    "preventDuplicates": false,
    "onclick": null,
    "showDuration": "300",
    "hideDuration": "1000",
    "timeOut": "5000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
};

class GrmConsole extends React.Component {
    state = {
        companiesOptions: [],
        citiesOptions: [],
        city: '',
        company: '',
        onFilter: false
    }

    filterHandler = () => {
        const city = this.state.city.value;
        const company = this.state.company.value;
        if (city || company) {
            this.props.filtratonFn(city, company);
            this.setState({onFilter: true});
        } else {
            toastr.clear();
            toastr.error('Виберіть фільтр');
        }
    }
    removeFilterHandler = () => {
        this.props.filtratonFn('', '');
        this.setState({
            onFilter: false,
            city: '',
            company: ''
        });
    }
    updateCompanies = () => {
        let tempCompaniesOptions = [];
        this.props.tableData.forEach(item => tempCompaniesOptions.push(item.company));
        tempCompaniesOptions = [...new Set(tempCompaniesOptions)];

        let result = [];
        tempCompaniesOptions.forEach(item => result.push({value: item, label: item}));
        this.setState({companiesOptions: result});
    }
    updateCities = () => {
        let tempCompaniesOptions = [];
        this.props.tableData.forEach(item => tempCompaniesOptions.push(item.city));
        tempCompaniesOptions = [...new Set(tempCompaniesOptions)];

        let result = [];
        tempCompaniesOptions.forEach(item => result.push({value: item, label: item}));
        this.setState({citiesOptions: result});
    }
    compareArrays = (arrayOne, arrayTwo) => {
        if (!arrayTwo)
            return false;
        if (arrayTwo === arrayOne)
            return true;
        if (arrayOne.length !== arrayTwo.length)
            return false;

        for (let i = 0, l = arrayOne.length; i < l; i++) {
            if (arrayOne[i] instanceof Array && arrayTwo[i] instanceof Array) {
                if (!this.compareArrays(arrayOne[i], arrayTwo[i]))
                    return false;
            } else if (arrayOne[i] !== arrayTwo[i]) {
                return false;
            }
        }
        return true;
    }

    componentDidMount() {
        this.updateCompanies();
        this.updateCities();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.clearFilter) {
            this.setState({
                city: '',
                company: '',
                onFilter: false
            });
            this.props.changeClearFilter(false);
        }
        if (!prevProps.tableData.length && !this.props.tableData.length) return;
        if (this.compareArrays(prevProps.tableData, this.props.tableData)) return;
        this.updateCompanies();
        this.updateCities();
    }

    clearInput = (key) => {
        const obj = {};
        obj[key] = '';
        this.setState(obj);
    };

    render() {
        const customStyles = {
                dropdownIndicator: () => ({
                    display: 'none'
                }),
                indicatorsContainer: () => ({
                    display: 'none'
                }),
                container: (provided) => ({
                    ...provided,
                    height: '40px',
                    width: '100%',
                }),
                control: (provided) => ({
                    ...provided,
                    height: '100%',
                    outline: 'none',
                    background: 'var(--white)',
                    border: '1px solid var(--white)',
                    transition: 'all .5s',
                    borderRadius: '5px',
                    boxShadow: 'none',
                    cursor: 'pointer',
                    position: 'relative',
                    zIndex: '2',
                    "&:hover": {
                        borderColor: '1px solid #D5D4DD',
                    },
                    '&:before': {
                        content: `url(${SearchIcon})`,
                        position: 'absolute',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        left: '14px',
                        height: '23px'
                    },
                    '&:after': {
                        content: `url(${BottomArrowIcon})`,
                        position: 'absolute',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        right: '17px'
                    }
                }),
                input: (provided) => ({
                    ...provided,
                    fontFamily: 'Poppins, sans-serif',
                    paddingLeft: '40px',
                }),
                placeholder: (provided) => ({
                    ...provided,
                    fontFamily: 'Poppins, sans-serif',
                    color: 'var(--text-2)',
                    paddingLeft: '40px',
                    letterSpacing: '0.5px',
                    fontSize: '14px',
                    opacity: '.6',
                }),
                menu: (provided) => ({
                    ...provided,
                    margin: '0',
                    top: '0',
                    paddingTop: '34px',
                    borderRadius: '5px',
                    background: 'var(--white)',
                    boxShadow: '0 0 15px rgba(0, 0, 0, 0.1)',
                    overflow: 'hidden',
                }),
                menuList: (provided) => ({
                    ...provided,
                    padding: '0',
                    maxHeight: '410px',
                    marginTop: '6px',
                    borderRadius: '0 0 5px 5px',
                    border: '1px solid var(--divider)',
                }),
                option: (provided) => ({
                    ...provided,
                    transition: 'all .3s',
                    background: 'var(--white)',
                    cursor: 'pointer',
                    paddingLeft: '18px',
                    fontSize: '16px',
                    color: 'var(--text-1)',
                    borderBottom: '1px solid var(--divider)',
                    '&:nth-last-of-type(1)': {
                        borderBottom: 'none'
                    },
                    '&:hover': {
                        background: 'rgba(255, 104, 83, 0.1)'
                    }
                }),
                singleValue: (provided) => ({
                    ...provided,
                    paddingLeft: '40px',
                }),
            };

        return (
            <div className="grm-console">
                <div className="grm-console__selects">
                    <div>
                        <Select
                            className={`grm-console__select${this.state.onFilter ? ' no-drop' : ''}`}
                            id="grmCities"
                            value={this.state.city}
                            onChange={val => this.setState({city: val})}
                            options={this.state.citiesOptions}
                            styles={customStyles}
                            placeholder="Введіть місто"
                            noOptionsMessage={() => 'Не знайдено'}
                            isClearable={true}
                        />
                        <button
                            onClick={() => this.clearInput('city')}
                            className={`btn-clear${this.state.city ? this.state.onFilter ? ' hidden' : '' : ' hidden'}`}
                        ><CloseIcon/></button>
                    </div>
                    <div>
                        <Select
                            className={`grm-console__select${this.state.onFilter ? ' no-drop' : ''}`}
                            id="grmCompanies"
                            value={this.state.company}
                            onChange={val => this.setState({company: val})}
                            options={this.state.companiesOptions}
                            styles={customStyles}
                            placeholder="Введіть компанію"
                            noOptionsMessage={() => 'Не знайдено'}
                            isClearable={true}
                        />
                        <button
                            onClick={() => this.clearInput('company')}
                            className={`btn-clear${this.state.company ? this.state.onFilter ? ' hidden' : '' : ' hidden'}`}
                        ><CloseIcon/></button>
                    </div>
                </div>
                {this.state.onFilter ?
                    <button className="grm-console__btn btn btn--primary"
                            onClick={this.removeFilterHandler}>Скасувати</button>
                    :
                    <button className="grm-console__btn btn btn--primary"
                            onClick={this.filterHandler}>Застосувати</button>
                }
            </div>
        );
    }
}

export default GrmConsole;