import './AddGrmModal.css';
import {ReactComponent as CloseIcon} from "../../../images/close_icon.svg";
import ModalOverlay from "../../UI/ModalOverlay/ModalOverlay";
import Select from "react-select";
import React from "react";
import InputMask from "react-input-mask";
import GooglePlacesAutocomplete, {geocodeByAddress, getLatLng} from "react-google-places-autocomplete";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxGl from '!mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import axios from "axios";
import Api from "../../../Api/Api";
import toastr from "toastr";
import 'toastr/build/toastr.min.css';
import RemoveToken from "../../../Api/RemoveToken";
import GrmInfo from "../../GrmInfo/GrmInfo";
import SearchIcon from "../../../images/search_icon.svg";
import BottomArrowIcon from "../../../images/bt_arrow.svg";

toastr.options = {
    "closeButton": false,
    "debug": false,
    "newestOnTop": false,
    "progressBar": true,
    "positionClass": "toast-top-right",
    "preventDuplicates": false,
    "onclick": null,
    "showDuration": "300",
    "hideDuration": "1000",
    "timeOut": "5000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
};

class AddGrmModal extends React.Component {
    state = {
        lng: 30.542293,
        lat: 50.467119,
        zoom: 10.54,
        marker: '',
        markerLngLat: [],
        name: '',
        city: '',
        company: '',
        phone: '',
        address: '',
        isValidAddress: true
    }

    constructor(props) {
        super(props)
        this.formRef = React.createRef();
        this.cityRef = React.createRef();
        this.addressRef = React.createRef();
        this.onClickSubmit = this.onClickSubmit.bind(this);
        this.mapContainer = React.createRef();
        this.map = React.createRef();
        mapboxGl.accessToken = 'pk.eyJ1IjoiZG9kb3RhcCIsImEiOiJjbGNleWkwd2wwZTBpM3JuMDliaTczMmdkIn0.Stdk96F101S91AMX_S5feA';
    }

    onClickSubmit(e) {
        e.preventDefault();
        let
            data = {},
            errors = 0;

        for (let key of this.formRef.current.elements) {
            if (key.name) {
                if (key.value.trim() === '') {
                    key.classList.add('_error');
                    errors++;
                } else {
                    data[key.name] = key.value;
                }
            }
        }

        if (this.state.city === '') {
            this.cityRef.current.controlRef.classList.add('_error');
            errors++;
        }
        if (!this.state.markerLngLat.length) {
            document.querySelector('#addAdrress > div').classList.add('_error');
            errors++;
        }

        data.coordinates = {
            lng: this.state.markerLngLat[0],
            lat: this.state.markerLngLat[1],
        };

        if (!errors) this.addGrm(data);
    }

    addGrm = (data) => {
        axios({
            method: 'post',
            url: Api.link + 'gmr/create',
            data: {
                name: data.name,
                phone: data.phone.replace(/\D+/g, ''),
                company: data.company,
                city: data.city,
                longitude: data.coordinates.lng,
                latitude: data.coordinates.lat,
                token: localStorage.getItem('token'),
            }
        }).then(res => {
            if (res.data.success) {
                this.props.addCb();
                this.props.closeCb();
                this.clearForm();
                toastr.clear();
                toastr.success('ГМР успішно створено');
            }
        }).catch(RemoveToken);
    }

    clearForm = (e) => {
        if (e) e.preventDefault();
        setTimeout(() => {
            if (this.state.marker) this.state.marker.remove();
            this.setState({
                name: '',
                city: '',
                company: '',
                phone: '',
                address: '',
                markerLngLat: [],
                marker: ''
            });
            this.map.current.flyTo({
                center: [this.state.lng, this.state.lat],
                zoom: 10.54,
                duration: 1000,
                essential: true
            });
            this.cityRef.current.controlRef.classList.remove('_error');
            document.querySelector('#addAdrress > div').classList.remove('_error');
            for (let key of this.formRef.current.elements) {
                key.classList.remove('_error');
            }
        }, 300);
    }

    selectCoordinatesHandler = (val) => {
        document.querySelector('#addAdrress > div').classList.remove('_error');
        this.setState({
            address: val
        })
        geocodeByAddress(val.label)
            .then(results => getLatLng(results[0]))
            .then(({lat, lng}) => {
                    const moveTo = {
                        center: [lng, lat],
                        zoom: 15,
                    };

                    if (this.state.marker) this.state.marker.remove();
                    this.setState({
                        marker: new mapboxGl.Marker(<div
                            className="marker"></div>).setLngLat([lng, lat]).addTo(this.map.current),
                        markerLngLat: [lng, lat]
                    });
                    this.map.current.flyTo({
                        ...moveTo,
                        duration: 1000,
                        essential: true
                    });
                }
            );
    }

    componentDidMount() {
        if (this.map.current) return;
        this.map.current = new mapboxGl.Map({
            container: this.mapContainer.current,
            style: 'mapbox://styles/dodotap/clceyfxrx001a14qwip9b4ea0',
            center: [this.state.lng, this.state.lat],
            zoom: this.state.zoom,
            resize: true,
        });

        const marker = new mapboxGl.Marker();
        this.map.current.on('click', add_marker.bind(this));

        function add_marker(event) {
            const
                coordinates = event.lngLat,
                lng = coordinates.lng,
                lat = coordinates.lat;

            if (this.state.marker) this.state.marker.remove();
            document.querySelector('#addAdrress > div').classList.remove('_error');
            marker.setLngLat(coordinates).addTo(this.map.current);
            this.map.current.flyTo({
                center: [lng, lat],
                zoom: 13,
                duration: 1000,
                essential: true
            });
            this.setState({marker, markerLngLat: [lng, lat]});
        }
    }

    clearAddress = (e) => {
        e.preventDefault();
        if (this.state.marker) this.state.marker.remove();
        this.setState({
            address: '',
            markerLngLat: [],
            marker: ''
        });
        this.map.current.flyTo({
            center: [this.state.lng, this.state.lat],
            zoom: 10.54,
            duration: 1000,
            essential: true
        });
    }

    render() {
        const
            customStyles = GrmInfo.modals.customStyles,
            customStylesAddress = Object.assign({}, GrmInfo.modals.customStyles),
            citiesOptions = GrmInfo.citiesOptions;


        customStylesAddress.singleValue = (provided) => ({
            ...provided,
            padding: '0 40px',
        });
        customStylesAddress.placeholder = (provided) => ({
            ...provided,
            fontFamily: 'Roboto, sans-serif',
            color: this.state.markerLngLat.length ? 'var(--text-1)' : 'var(--text-2)',
            paddingLeft: '40px',
            fontSize: '18px'
        });
        customStylesAddress.control = (provided) => ({
            ...provided,
            height: '100%',
            outline: 'none',
            background: 'var(--white)',
            border: '1px solid #9F9F9F',
            transition: 'all .5s',
            borderRadius: '10px',
            boxShadow: '0 0 8px rgba(0, 0, 0, 0.1)',
            cursor: 'pointer',
            position: 'relative',
            zIndex: '3',
            "&:hover": {},
            '&:before': {
                content: `url(${SearchIcon})`,
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                left: '14px',
                height: '23px'
            },
            '&:after': {
                content: `url(${BottomArrowIcon})`,
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                right: '17px'
            }
        });
        customStylesAddress.menu = (provided) => ({
            ...provided,
            margin: '0',
            top: '0',
            paddingTop: '50px',
            borderRadius: '10px',
            border: '1px solid #9F9F9F',
            background: 'var(--white)',
            boxShadow: '0 0 15px rgba(0, 0, 0, 0.1)',
            overflow: 'hidden',
            zIndex: 2
        });

        return (
            <div className={`add-modal${this.props.opened ? ' opened' : ''}`}>
                <div className="add-modal__content">
                    <h3 className="add-modal__title">Додавання ГМР</h3>
                    <button className="add-modal__close" onClick={(e) => {
                        this.props.closeCb();
                        this.clearForm(e);
                    }}><CloseIcon/></button>
                    <form ref={this.formRef} action="src/components/Modals/GrmModals/AddGrmModal"
                          onSubmit={this.onClickSubmit} className="add-modal__form form">
                        <div className="form-control">
                            <label className="label" htmlFor="nameGrm">Ім'я ГМР</label>
                            <input
                                type="text"
                                name="name"
                                className="input"
                                id="nameGrm"
                                value={this.state.name}
                                onInput={e => {
                                    e.target.classList.remove('_error');
                                    this.setState({name: e.target['value']})
                                }}
                                placeholder="Введіть ім'я"/>
                        </div>
                        <div className="form-control zIndex">
                            <label className="label" htmlFor="city">Місто</label>
                            <Select
                                id="city"
                                name="city"
                                ref={this.cityRef}
                                options={citiesOptions}
                                styles={customStyles}
                                value={this.state.city}
                                onChange={val => {
                                    this.cityRef.current.controlRef.classList.remove('_error');
                                    this.setState({city: val});
                                }}
                                placeholder="Введіть місто"
                                noOptionsMessage={() => 'Не знайдено'}
                            />
                            <button
                                className={`btn-clear${this.state.city ? '' : ' hidden'}`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({city: ''});
                                }}
                            >
                                <CloseIcon/></button>
                        </div>
                        <div className="form-control double">
                            <div>
                                <label className="label" htmlFor="companyGrm">Компанія</label>
                                <input
                                    type="text"
                                    name="company"
                                    className="input"
                                    id="companyGrm"
                                    value={this.state.company}
                                    onInput={e => {
                                        e.target.classList.remove('_error');
                                        this.setState({company: e.target['value']})
                                    }}
                                    placeholder="Введіть компанію"/>
                            </div>
                            <div>
                                <label className="label" htmlFor="phoneGrm">Телефон</label>
                                <InputMask
                                    mask="+38 (099) 999 99 99"
                                    placeholder="+38 (0**) *** ** **"
                                    maskChar="*"
                                    name="phone"
                                    id="phoneGrm"
                                    className="input"
                                    value={this.state.phone}
                                    onInput={e => {
                                        e.target.classList.remove('_error');
                                        this.setState({phone: e.target['value']})
                                    }}
                                />
                            </div>
                        </div>
                        <div className="form-control">
                            <label className="label" htmlFor="address">Координати</label>
                            <GooglePlacesAutocomplete
                                apiKey="AIzaSyDebR-ZmV52u73QTs31NnS18N6WGvfDNoM"
                                autocompletionRequest={{
                                    componentRestrictions: {
                                        country: ["ua"]
                                    }
                                }}
                                selectProps={{
                                    placeholder: this.state.markerLngLat.length ?
                                        `${this.state.markerLngLat[0]}, ${this.state.markerLngLat[1]}`
                                        :
                                        "Введіть адресу ГМР",
                                    noOptionsMessage: () => "Введіть текст у пошуковий рядок",
                                    id: 'addAdrress',
                                    ref: this.addressRef,
                                    onChange: this.selectCoordinatesHandler,
                                    value: this.state.address,
                                    styles: customStylesAddress
                                }}
                                onLoadFailed={(error) => {
                                    console.log(error);
                                }}
                            />
                            <button
                                className={`btn-clear${this.state.markerLngLat.length === 0 ? ' hidden' : ''}`}
                                onClick={this.clearAddress}
                            >
                                <CloseIcon/></button>
                        </div>
                        <div ref={this.mapContainer} className="form-map"></div>
                        <div className="form-buttons">
                            <button className="btn btn--secondary" onClick={(e) => {
                                e.preventDefault();
                                this.props.closeCb();
                                this.clearForm();
                            }}>Скасувати
                            </button>
                            <button className="btn btn--primary" type="submit">Додати</button>
                        </div>
                    </form>
                </div>
                <ModalOverlay cb={(e) => {
                    this.props.closeCb();
                    this.clearForm(e);
                }}/>
            </div>
        );
    }
}

export default AddGrmModal;