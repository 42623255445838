import React from "react";
import './AddUserModal.css';
import {ReactComponent as CloseIcon} from "../../../images/close_icon.svg";
import InputMask from "react-input-mask";
import ModalOverlay from "../../UI/ModalOverlay/ModalOverlay";
import {ReactComponent as AddIcon} from "../../../images/add.svg";
import axios from "axios";
import Api from "../../../Api/Api";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

toastr.options = {
    "closeButton": false,
    "debug": false,
    "newestOnTop": false,
    "progressBar": true,
    "positionClass": "toast-top-right",
    "preventDuplicates": false,
    "onclick": null,
    "showDuration": "300",
    "hideDuration": "1000",
    "timeOut": "5000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
};

class AddUserModal extends React.Component {
    state = {
        rights: '1',
        image: '',
        name: '',
        phone: '',
        email: '',
        job_title: ''
    }

    constructor(props) {
        super(props)
        this.formRef = React.createRef();
        this.onClickSubmit = this.onClickSubmit.bind(this);
    }

    onClickSubmit(e) {
        e.preventDefault();
        let
            data = {},
            errors = 0;

        const error = (input) => {
            input.classList.add('_error');
            errors++;
        }
        const success = (input) => {
            data[input.name] = input.value;
        }

        for (let key of this.formRef.current.elements) {
            if (key.name && key.name !== 'image') {
                if (key.type === 'radio') {
                    if (key.checked) {
                        data[key.name] = key.value;
                    }
                } else {
                    if (key.name === 'phone' || key.name === 'email') {
                        const regex = key.name === 'phone' ?
                            /^\+?3?8?(0\d{9})$/ :
                            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

                        const value = key.name === 'phone' ? key.value.replace(/\D+/g, '') : key.value;

                        if (regex.test(value)) {
                            success(key);
                        } else {
                            error(key);
                        }
                    } else {
                        if (key.value.trim() === '') {
                            error(key);
                        } else {
                            success(key);
                        }
                    }
                }
            }
        }

        if (!errors) {
            axios({
                method: 'post',
                url: Api.link + 'user/create',
                data: {
                    name: data.name,
                    phone: data.phone.replace(/\D+/g, ''),
                    email: data.email,
                    job_title: data.position,
                    level: data.rights
                }
            }).then(res => {
                if (res.data.success) {
                    this.props.addCb();
                    this.props.updateCb();
                    this.props.closeCb();
                    setTimeout(() => this.setState({
                        rights: '1',
                        image: '',
                        name: '',
                        phone: '',
                        email: '',
                        job_title: ''
                    }), 300);
                    toastr.clear();
                    toastr.success('Користувач успішно створений');
                }
            }).catch((error) => console.log(error));
        }
    }

    imgUploadHandler = (e) => {
        const reader = new FileReader();
        const file = e.target.files[0];

        reader.onload = (upload) => {
            this.setState({
                image: upload.target.result
            });
        };
        reader.readAsDataURL(file);
    }

    closeHandler = (e) => {
        e.preventDefault();
        this.props.closeCb();
        setTimeout(() => {
            this.setState({
                rights: '1',
                image: '',
                name: '',
                phone: '',
                email: '',
                job_title: ''
            });
            for (let key of this.formRef.current.elements) {
                key.classList.remove('_error');
            }
        }, 300);
    }

    render() {
        return (
            <div className={`add-user${this.props.opened ? ' opened' : ''}`}>
                <div className="add-user__content">
                    <h3 className="add-user__title">Додавання користувача</h3>
                    <button className="add-user__close" onClick={this.closeHandler}><CloseIcon/></button>
                    <form ref={this.formRef} action="" onSubmit={this.onClickSubmit} className="add-user__form form">
                        <div className="form-control double top">
                            <div className={this.state.image ? 'uploaded' : ''}>
                                <label className="label" htmlFor="imageUser">Фото користувача</label>
                                <input type="file"
                                       name="image"
                                       accept=".png,.jpg,.svg,.jpeg"
                                       className="input-file"
                                       id="imageUser"
                                       onChange={this.imgUploadHandler}
                                />
                                <label htmlFor="imageUser" className="label-file"><AddIcon/></label>
                                <span className="uploaded-img">
                                    <img src={this.state.image ? this.state.image : ''} alt="user_img"/>
                                    <button className="uploaded-img__remove" onClick={() => this.setState({image: ''})}><CloseIcon/></button>
                                </span>
                            </div>
                            <div>
                                <span className="label" style={({marginBottom: '15px'})}>Права доступу</span>
                                <label className="form-switch">
                                    <input
                                        type="radio"
                                        value="1"
                                        name="rights"
                                        checked={this.state.rights === '1'}
                                        onChange={(e) => this.setState({rights: e.target.value})}
                                    />
                                    <span className="form-switch-slider"></span>
                                    <span className="form-switch-label">Менеджер</span>
                                </label>
                                <label className="form-switch">
                                    <input
                                        type="radio"
                                        value="2"
                                        name="rights"
                                        checked={this.state.rights === '2'}
                                        onChange={(e) => this.setState({rights: e.target.value})}
                                    />
                                    <span className="form-switch-slider"></span>
                                    <span className="form-switch-label">Адміністратор</span>
                                </label>
                            </div>
                        </div>
                        <div className="form-control">
                            <label className="label" htmlFor="nameUser">Ім'я Прізвище</label>
                            <input
                                value={this.state.name}
                                onInput={e => {
                                    e.target.classList.remove('_error');
                                    this.setState({name: e.target.value});
                                }}
                                type="text"
                                name="name"
                                className="input"
                                id="nameUser"
                                placeholder="Ім'я Прізвище"/>
                        </div>
                        <div className="form-control">
                            <label className="label" htmlFor="phoneUser">Телефон</label>
                            <InputMask
                                mask="+38 (099) 999 99 99"
                                placeholder="+38 (0**) *** ** **"
                                maskChar="*"
                                name="phone"
                                id="phoneUser"
                                className="input"
                                value={this.state.phone}
                                onInput={e => {
                                    e.target.classList.remove('_error');
                                    this.setState({phone: e.target.value})
                                }}
                            />
                        </div>
                        <div className="form-control">
                            <label className="label" htmlFor="emailUser">Email (електронна пошта)</label>
                            <input
                                value={this.state.email}
                                onInput={e => {
                                    e.target.classList.remove('_error');
                                    this.setState({email: e.target.value});
                                }}
                                type="email"
                                className="input"
                                id="emailUser"
                                name="email"
                                placeholder="email@gmail.com"/>
                        </div>
                        <div className="form-control">
                            <label className="label" htmlFor="positionUser">Посада</label>
                            <input
                                value={this.state.job_title}
                                onInput={e => {
                                    e.target.classList.remove('_error');
                                    this.setState({job_title: e.target.value})
                                }}
                                type="text"
                                className="input"
                                id="positionUser"
                                name="position"
                                placeholder="Посада"/>
                        </div>
                        <div className="form-buttons add-user__buttons">
                            <button className="btn btn--secondary" onClick={this.closeHandler}>Скасувати</button>
                            <button className="btn btn--primary" type="submit">Додати</button>
                        </div>
                    </form>
                </div>
                <ModalOverlay cb={this.closeHandler}/>
            </div>
        );
    }
}

export default AddUserModal;