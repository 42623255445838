import './GrmModal.css';
import {ReactComponent as CloseIcon} from "../../../images/close_icon.svg";
import ModalOverlay from "../../UI/ModalOverlay/ModalOverlay";
import Table from "../../UI/Table/Table";
import {ReactComponent as GrmTableIcon} from "../../../images/grm-table_icon.svg";
import {useState} from "react";
import {geocodeByAddress, getLatLng} from "react-google-places-autocomplete";
import axios from "axios";
import Api from "../../../Api/Api";
import RemoveToken from "../../../Api/RemoveToken";

function GrmModal({opened, closeCb, data, clearData}) {
    const [tableData, setTableData] = useState({
        head: ['ID', 'Ім\'я ГМР', 'Телефон', 'Компанія', 'Час  40км/год', 'Відстань'],
        body: [],
        preloading: true
    });

    function getData(data, title) {
        geocodeByAddress(data.label)
            .then(results => getLatLng(results[0]))
            .then(({lat, lng}) => {
                    axios({
                        method: 'post',
                        url: Api.link + 'map/gmr/list',
                        data: {
                            phone: localStorage.getItem('phone'),
                            token: localStorage.getItem('token'),
                            latitude: lat,
                            longitude: lng,
                        }
                    }).then(res => {
                        if (res.data.success) {
                            const result = [];
                            const data = res.data.data.sort((a, b) => a.distance - b.distance);

                            data.forEach(city => {
                                let
                                    time = +city.time,
                                    distance = +city.distance / 1000;
                                result.push([
                                    city.id,
                                    city.name,
                                    city.phone,
                                    city.company,
                                    {
                                        value: `${time.toFixed(2)} хв.`,
                                        customStyles: {
                                            textDecoration: 'underline'
                                        }
                                    },
                                    {
                                        icon: <GrmTableIcon/>,
                                        value: ` ${distance.toFixed(2)} км.`,
                                        customStyles: {
                                            display: 'flex',
                                            alignItems: 'center',
                                            color: 'var(--main-1)'
                                        }
                                    }
                                ]);
                            });
                            setTableData({
                                ...tableData,
                                body: result,
                                title,
                                preloading: false
                            });
                            clearData();
                        }
                    }).catch(RemoveToken);
                }
            );
    }

    if (Object.keys(data).length) getData(data, data.label);
    return (
        <div className={`grm-modal${opened ? ' opened' : ''}`}>
            <div className="grm-modal__content">
                <div className="grm-modal__heading">
                    <h3 className="grm-modal__title">ГМР за адресою {tableData.title}</h3>
                    <button className="grm-modal__close" onClick={closeCb}><CloseIcon/></button>
                </div>
                <Table data={tableData} preloading={tableData.preloading} customClass="grm-modal__table" />
            </div>
            <ModalOverlay cb={closeCb}/>
        </div>
    );

}

export default GrmModal;